import { React, useState, useRef, useEffect } from "react";
import { onValue, doc, updateDoc } from "firebase/firestore";
import db from "../firebase/Firestore";
import * as d3 from "d3";
import store from "./store";
import { onSnapshot } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight,faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { set } from "firebase/database";

const GraphTest = () => {
  //create a hardcoded array of arrays each containign 2 numbers
  const [bored, setBored] = useState([]);

  const [tired, setTired] = useState([]);

  const [slow, setSlow] = useState([]);

  const [fast, setFast] = useState([]);

  const [repeat, setRepeat] = useState([]);

  const [overwhelmed, setOverwhelmed] = useState([]);

  const [lost, setLost] = useState([]);

  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());

  const [startTime, setStartTime] = useState(Math.floor(new Date() / 1000));

  const [disableLeftArrow,setDisableLeftArrow]=useState(true);

  const [disableRightArrow,setDisableRightArrow]=useState(true);

  let snapCount=0;

  let totalPoints=-1;

  async function getCoordinates(){
    const dateObject = new Date();
    let time = Math.floor(dateObject / 1000);
    time=time-startTime;
    let x = Math.round(time/60);
    if(totalPoints===0){
      nextPoint.x=x;
      nextPoint.y=1;
    }
    else if(nextPoint.x!==x){
      nextPoint.x=x;
      nextPoint.y=1;
    }
    else{
      nextPoint.y++;
    }
  }

  function resetPoint()
  {
    nextPoint.x=-1;
    nextPoint.y=-1;
  }

  let nextPoint = {
    x:-1,
    y:-1,
  }

async function sendPointsToDB(){
    let boredObjects=[];
    for(let i=0;i<bored.length;i++){
        boredObjects.push({x:bored[i][0], y:bored[i][1]});
    }
    let tiredObjects=[];
    for(let i=0;i<tired.length;i++){
        tiredObjects.push({x:tired[i][0], y:tired[i][1]});
    }
    let slowObjects=[];
    for(let i=0;i<slow.length;i++){
        slowObjects.push({x:slow[i][0], y:slow[i][1]});
    }
    let fastObjects=[];
    for(let i=0;i<fast.length;i++){
        fastObjects.push({x:fast[i][0], y:fast[i][1]});
    }
    let repeatObjects=[];
    for(let i=0;i<repeat.length;i++){
        repeatObjects.push({x:repeat[i][0], y:repeat[i][1]});
    }
    let overwhelmedObjects=[];
    for(let i=0;i<overwhelmed.length;i++){
        overwhelmedObjects.push({x:overwhelmed[i][0], y:overwhelmed[i][1]});
    }
    let lostObjects=[];
    for(let i=0;i<lost.length;i++){
        lostObjects.push({x:lost[i][0], y:lost[i][1]});
    }
    await updateDoc(doc(db,"users",store.getState().userID,"classes",store.getState().className,"lectures",store.getState().lectureName),{
      boredArray:boredObjects,
      tiredArray:tiredObjects,
      slowArray:slowObjects,
      fastArray:fastObjects,
      repeatArray:repeatObjects,
      overwhelmedArray:overwhelmedObjects,
      lostArray:lostObjects,
      lectureLength: store.getState().xMax
    });
  }

  //Set the initial xLimit that will change with store.subscribe()
  //create a ref to the svg element
  const svgRef = useRef();
  //Creating the graph function, this must contain updatePlot due to scope issues
  async function createGraph() {
    // set the dimensions and margins of the graph, essentially creates the size of the window on screen
    const margin = { top: 10, right: 30, bottom: 30, left: 20 },
      width = 760 - margin.left - margin.right,
      height = 500 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    var svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    //Define the x and y scale
    var xScale = d3.scaleLinear().domain([store.getState().xStart, store.getState().xLimit]).range([0, width]);
    var yScale = d3.scaleLinear().domain([0, 10]).range([height, 0]);

    // Add the axises
    const xAxis = svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale));

    const yAxis = svg.append("g").call(d3.axisLeft(yScale));

    // Add dots
    svg
      .selectAll("bored")
      .data(bored)
      .enter()
      .append("text")
      .attr("class", "bored")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .text("😐");

    svg
      .selectAll("tired")
      .data(tired)
      .enter()
      .append("text")
      .attr("class", "tired")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .text("😴");

    svg
      .selectAll("slow")
      .data(slow)
      .enter()
      .append("text")
      .attr("class", "slow")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .text("🐌");

      svg
      .selectAll("fast")
      .data(fast)
      .enter()
      .append("text")
      .attr("class", "fast")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .text("💨");

      svg
      .selectAll("repeat")
      .data(repeat)
      .enter()
      .append("text")
      .attr("class", "repeat")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .text("🔁");

      svg
      .selectAll("overwhelmed")
      .data(overwhelmed)
      .enter()
      .append("text")
      .attr("class", "overwhelmed")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .text("😰");

      svg
      .selectAll("lost")
      .data(lost)
      .enter()
      .append("text")
      .attr("class", "lost")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .text("🧭");

    //Update graph everytime the state is changed, this works because we set the xLimit as a state value so anytime it is changed graph updates
    store.subscribe(() => {
      updatePlotAxis();
    });
   if(store.getState().role==='teacher'){
    let path= doc(db,"users",store.getState().userID,"classes",store.getState().className,"lectures",store.getState().lectureName);
    const newPoints = onSnapshot(path, (doc) => {
      const data = doc.data();
      console.log(snapCount)
      if(snapCount%2===0){
        getCoordinates();
        if(snapCount===2){
          nextPoint.y=1;
        }

      }
      snapCount++;
      if(data.bored != null){
        totalPoints++;
        if(bored.length<data.bored){
          for(let i=bored.length;i<data.bored;i++){
            bored.push([nextPoint.x,nextPoint.y]);
          }
        }
        if(tired.length < data.tired){
          for(let i=tired.length;i<data.tired;i++){
            tired.push([nextPoint.x,nextPoint.y]);
          }
        }
        if(slow.length < data.slow){
          for(let i=slow.length;i<data.slow;i++){
            slow.push([nextPoint.x,nextPoint.y]);
          }
        }
        if(fast.length < data.fast){
          for(let i=fast.length;i<data.fast;i++){
            fast.push([nextPoint.x,nextPoint.y]);
          }
        }
        if(repeat.length < data.repeat){
          for(let i=repeat.length;i<data.repeat;i++){
            repeat.push([nextPoint.x,nextPoint.y]);
          }
        }
        if(overwhelmed.length < data.overwhelmed){
          for(let i=overwhelmed.length;i<data.overwhelmed;i++){
            overwhelmed.push([nextPoint.x,nextPoint.y]);
          }
        }
        if(lost.length < data.lost){
          for(let i=lost.length;i<data.lost;i++){
            lost.push([nextPoint.x,nextPoint.y]);
          }
        }
      }
      sendPointsToDB();
      updatePlotPoint();
    });
  }
  else if(store.getState().role==='student'){
    let path= doc(db,"users",store.getState().userID);
    const newPoints = onSnapshot(path, (doc) => {
      const data = doc.data();
      getCoordinates();
      if(data.bored != null){
        totalPoints++;
        if(bored.length<data.bored){
          for(let i=bored.length;i<data.bored;i++){
            bored.push([nextPoint.x,nextPoint.y]);
          }
        }
        if(tired.length < data.tired){
          for(let i=tired.length;i<data.tired;i++){
            tired.push([nextPoint.x,nextPoint.y]);
          }
        }
        if(slow.length < data.slow){
          for(let i=slow.length;i<data.slow;i++){
            slow.push([nextPoint.x,nextPoint.y]);
          }
        }
        if(fast.length < data.fast){
          for(let i=fast.length;i<data.fast;i++){
            fast.push([nextPoint.x,nextPoint.y]);
          }
        }
        if(repeat.length < data.repeat){
          for(let i=repeat.length;i<data.repeat;i++){
            repeat.push([nextPoint.x,nextPoint.y]);
          }
        }
        if(overwhelmed.length < data.overwhelmed){
          for(let i=overwhelmed.length;i<data.overwhelmed;i++){
            overwhelmed.push([nextPoint.x,nextPoint.y]);
          }
        }
        if(lost.length < data.lost){
          for(let i=lost.length;i<data.lost;i++){
            lost.push([nextPoint.x,nextPoint.y]);
          }
        }

      }
      else{
        
      }
      updatePlotPoint();
    });
  }

    function updatePlotAxis() {

      // Update X axis
      let newX = store.getState().xLimit;
      //This updates the xScale to the new axis length so the points will be updated correctly
      xScale = d3.scaleLinear().domain([store.getState().xStart, store.getState().xLimit]).range([0, width]);
      xAxis.transition().duration(1000).call(d3.axisBottom(xScale));

      //This redraws all the points with the correct x and y scales
      svg
        .selectAll(".bored")
        .data(bored)
        .transition()
        .duration(1000)
        .attr("x", (d) => xScale(d[0]))
        .attr("y", (d) => yScale(d[1]));

        svg
        .selectAll(".tired")
        .data(tired)
        .transition()
        .duration(1000)
        .attr("x", (d) => xScale(d[0]))
        .attr("y", (d) => yScale(d[1]));

        svg
        .selectAll(".slow")
        .data(slow)
        .transition()
        .duration(1000)
        .attr("x", (d) => xScale(d[0]))
        .attr("y", (d) => yScale(d[1]));

        svg
        .selectAll(".fast")
        .data(fast)
        .transition()
        .duration(1000)
        .attr("x", (d) => xScale(d[0]))
        .attr("y", (d) => yScale(d[1]));

        svg
        .selectAll(".repeat")
        .data(repeat)
        .transition()
        .duration(1000)
        .attr("x", (d) => xScale(d[0]))
        .attr("y", (d) => yScale(d[1]));

        svg
        .selectAll(".overwhelmed")
        .data(overwhelmed)
        .transition()
        .duration(1000)
        .attr("x", (d) => xScale(d[0]))
        .attr("y", (d) => yScale(d[1]));

        svg
        .selectAll(".lost")
        .data(lost)
        .transition()
        .duration(1000)
        .attr("x", (d) => xScale(d[0]))
        .attr("y", (d) => yScale(d[1]));
      

    }

    function updatePlotPoint() {

      svg
      .selectAll(".bored")
      .data(bored)
      .enter()
      .append("text")
      .text("😐")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .attr("class", "bored");
      
      

      svg
      .selectAll(".tired")
      .data(tired)
      .enter()
      .append("text")
      .text("😴")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .attr("class", "tired");

      svg
      .selectAll(".slow")
      .data(slow)
      .enter()
      .append("text")
      .text("🐌")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .attr("class", "slow");

      svg
      .selectAll(".fast")
      .data(fast)
      .enter()
      .append("text")
      .text("💨")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .attr("class", "fast");

      svg
      .selectAll(".repeat")
      .data(repeat)
      .enter()
      .append("text")
      .text("🔁")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .attr("class", "repeat");

      svg
      .selectAll(".overwhelmed")
      .data(overwhelmed)
      .enter()
      .append("text")
      .text("😰")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .attr("class", "overwhelmed");

      svg
      .selectAll(".lost")
      .data(lost)
      .enter()
      .append("text")
      .text("🧭")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .attr("class", "lost");
    }
    resetPoint();
  }

  const timerRef = useRef(null);

  useEffect(() => {
    createGraph();
    changeInterval();
    return () => {
      clearInterval(timerRef.current);
    };
  }, [bored,overwhelmed,fast,slow,repeat,tired,lost]);

  const changeInterval = () => {
    timerRef.current = setInterval(() => {
    setCurrentTime(new Date().toLocaleTimeString());
      store.dispatch({type: 'xMaxUpdate', payload: store.getState().xMax+15});
      store.dispatch({type: 'xLimitEndUpdate', payload: store.getState().xMax});
      store.dispatch({type: 'xLimitStartUpdate', payload: store.getState().xMax-25});
      setDisableRightArrow(true);
      setDisableLeftArrow(false);
      setStartTime(Math.floor(new Date() / 1000))
      // createGraph();
      },900000); //900000 every 15 minutes
      return () => clearInterval(timerRef.current);
  }



  const updateUp = () => {
    console.log(store.getState())
    // update the xvalues, send to store, and update locally
    if(store.getState().xMax>store.getState().xLimit){
        store.dispatch({type: 'xLimitStartUpdate', payload: store.getState().xStart+15}); // + 15
        store.dispatch({type: 'xLimitEndUpdate', payload: store.getState().xLimit+15}); // + 15
        if(store.getState().xMax>store.getState().xLimit){
          setDisableRightArrow(false);
        }
        else{
          setDisableRightArrow(true);
        }
        setDisableLeftArrow(false);
    }
  };

  const updateDown = () => {
    // update the xvalues, send to store, and update locally
    console.log(store.getState())
    if(store.getState().xStart>0){
      store.dispatch({type: 'xLimitStartUpdate', payload: store.getState().xStart-15}); // + 15
      store.dispatch({type: 'xLimitEndUpdate', payload: store.getState().xLimit-15}); // + 15
      if(store.getState().xStart>0){
        setDisableLeftArrow(false);
      }
      else{
        setDisableLeftArrow(true);
      }
      setDisableRightArrow(false);
    }
  };

  return (
    <div className="bigContainer">
      <div style={{
                display: 'flex',
                justifyContent: 'center',
                verticalAlign: 'middle'
            }}className="graphContainer">
        <svg ref={svgRef}></svg>
        <div className="graphButtonContainer">
          {disableLeftArrow ? <div className="clearArrowButton"><FontAwesomeIcon size="2xl" icon={faArrowLeft}/></div> : <button className="solidArrowButton" onClick={()=>updateDown()}><FontAwesomeIcon size="2xl" icon={faArrowLeft}/></button>}
          {disableRightArrow ? <div className="clearArrowButton"><FontAwesomeIcon size="2xl" icon={faArrowRight}/></div> : <button className="solidArrowButton" onClick={()=>updateUp()}><FontAwesomeIcon size="2xl" icon={faArrowRight}/></button>}
        </div>
      </div>
    </div>
  );
};

export default GraphTest;
