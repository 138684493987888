import { React, useState, useRef, useEffect } from "react";
import { onValue, doc, updateDoc,getDoc} from "firebase/firestore";
import db from "../firebase/Firestore";
import * as d3 from "d3";
import store from "./store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight,faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const PastLectureGraph = () => {
  //create a hardcoded array of arrays each containign 2 numbers
  const [bored, setBored] = useState([]);

  const [tired, setTired] = useState([]);

  const [slow, setSlow] = useState([]);

  const [fast, setFast] = useState([]);

  const [repeat, setRepeat] = useState([]);

  const [overwhelmed, setOverwhelmed] = useState([]);

  const [refresh, setRefresh] = useState(0);

  const [lost, setLost] = useState([]);

  const [disableLeftArrow,setDisableLeftArrow]=useState(true);

  const [disableRightArrow,setDisableRightArrow]=useState(true);



  async function getPointsFromDB(){
    let data = await getDoc(doc(db,"users",store.getState().userID,"classes",store.getState().className,"lectures",store.getState().pastLecture));
    data=data.data();
    let temp=data.boredArray;
    for(let i=0;i<temp.length;i++){
      bored.push([temp[i].x,temp[i].y]);
    }
    temp=data.tiredArray;
    for(let i=0;i<temp.length;i++){
        tired.push([temp[i].x,temp[i].y]);
    }
    temp=data.slowArray;
    for(let i=0;i<temp.length;i++){
        slow.push([temp[i].x,temp[i].y]);
    }
    temp=data.fastArray;
    for(let i=0;i<temp.length;i++){
        fast.push([temp[i].x,temp[i].y]);
    }
    temp=data.repeatArray;
    for(let i=0;i<temp.length;i++){
        repeat.push([temp[i].x,temp[i].y]);
    }
    temp=data.overwhelmedArray;
    for(let i=0;i<temp.length;i++){
        overwhelmed.push([temp[i].x,temp[i].y]);
    }
    temp=data.lostArray;
    for(let i=0;i<temp.length;i++){
        lost.push([temp[i].x,temp[i].y]);
    }
    if(refresh===0){
        setRefresh(1);
    }
    store.dispatch({type: 'xMaxUpdate', payload: data.lectureLength});
    store.dispatch({type: 'xLimitEndUpdate', payload: 25});
    store.dispatch({type: 'xLimitStartUpdate', payload: 0});
  }

  //Set the initial xLimit that will change with store.subscribe()
  //create a ref to the svg element
  const svgRef = useRef();
  //Creating the graph function, this must contain updatePlot due to scope issues
  async function createGraph() {
    // set the dimensions and margins of the graph, essentially creates the size of the window on screen
    const margin = { top: 10, right: 30, bottom: 30, left: 60 },
      width = 760 - margin.left - margin.right,
      height = 500 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    var svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    //Define the x and y scale
    var xScale = d3.scaleLinear().domain([store.getState().xStart, store.getState().xLimit]).range([0, width]);
    var yScale = d3.scaleLinear().domain([0, 10]).range([height, 0]);

    // Add the axises
    const xAxis = svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale));

    const yAxis = svg.append("g").call(d3.axisLeft(yScale));

    // Add dots
    svg
      .selectAll("bored")
      .data(bored)
      .enter()
      .append("text")
      .attr("class", "bored")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .text("😐");

    svg
      .selectAll("tired")
      .data(tired)
      .enter()
      .append("text")
      .attr("class", "tired")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .text("😴");

    svg
      .selectAll("slow")
      .data(slow)
      .enter()
      .append("text")
      .attr("class", "slow")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .text("🐌");

      svg
      .selectAll("fast")
      .data(fast)
      .enter()
      .append("text")
      .attr("class", "fast")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .text("💨");

      svg
      .selectAll("repeat")
      .data(repeat)
      .enter()
      .append("text")
      .attr("class", "repeat")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .text("🔁");

      svg
      .selectAll("overwhelmed")
      .data(overwhelmed)
      .enter()
      .append("text")
      .attr("class", "overwhelmed")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .text("😰");

      svg
      .selectAll("lost")
      .data(lost)
      .enter()
      .append("text")
      .attr("class", "lost")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d) => yScale(d[1]))
      .text("🧭");

    //Update graph everytime the state is changed, this works because we set the xLimit as a state value so anytime it is changed graph updates
    store.subscribe(() => {
      updatePlotAxis();
    });

    function updatePlotAxis() {

      // Update X axis
      //This updates the xScale to the new axis length so the points will be updated correctly
      xScale = d3.scaleLinear().domain([store.getState().xStart, store.getState().xLimit]).range([0, width]);
      xAxis.transition().duration(1000).call(d3.axisBottom(xScale));

      //This redraws all the points with the correct x and y scales
      svg
        .selectAll(".bored")
        .data(bored)
        .transition()
        .duration(1000)
        .attr("x", (d) => xScale(d[0]))
        .attr("y", (d) => yScale(d[1]));

        svg
        .selectAll(".tired")
        .data(tired)
        .transition()
        .duration(1000)
        .attr("x", (d) => xScale(d[0]))
        .attr("y", (d) => yScale(d[1]));

        svg
        .selectAll(".slow")
        .data(slow)
        .transition()
        .duration(1000)
        .attr("x", (d) => xScale(d[0]))
        .attr("y", (d) => yScale(d[1]));

        svg
        .selectAll(".fast")
        .data(fast)
        .transition()
        .duration(1000)
        .attr("x", (d) => xScale(d[0]))
        .attr("y", (d) => yScale(d[1]));

        svg
        .selectAll(".repeat")
        .data(repeat)
        .transition()
        .duration(1000)
        .attr("x", (d) => xScale(d[0]))
        .attr("y", (d) => yScale(d[1]));

        svg
        .selectAll(".overwhelmed")
        .data(overwhelmed)
        .transition()
        .duration(1000)
        .attr("x", (d) => xScale(d[0]))
        .attr("y", (d) => yScale(d[1]));

        svg
        .selectAll(".lost")
        .data(lost)
        .transition()
        .duration(1000)
        .attr("x", (d) => xScale(d[0]))
        .attr("y", (d) => yScale(d[1]));
      }
}


  useEffect(() => {
    getPointsFromDB();
    createGraph();
    store.dispatch({type: 'xLimitStartUpdate', payload: 0});
    store.dispatch({type: 'xLimitEndUpdate', payload: 25});

  }, [refresh]);



  const updateUp = () => {
    // update the xvalues, send to store, and update locally
    if(store.getState().xMax>store.getState().xLimit){
        store.dispatch({type: 'xLimitStartUpdate', payload: store.getState().xStart+10}); // + 15
        store.dispatch({type: 'xLimitEndUpdate', payload: store.getState().xLimit+10}); // + 15
        if(store.getState().xMax>store.getState().xLimit){
          setDisableRightArrow(false);
        }
        else{
          setDisableRightArrow(true);
        }
        setDisableLeftArrow(false);
    }
  };

  const updateDown = () => {
    // update the xvalues, send to store, and update locally
    if(store.getState().xStart>0){
      store.dispatch({type: 'xLimitStartUpdate', payload: store.getState().xStart-10}); // + 15
      store.dispatch({type: 'xLimitEndUpdate', payload: store.getState().xLimit-10}); // + 15
      if(store.getState().xStart>0){
        setDisableLeftArrow(false);
      }
      else{
        setDisableLeftArrow(true);
      }
      setDisableRightArrow(false);
    }
  };

  return (
    <div className="bigContainer">
      <div style={{
                display: 'flex',
                justifyContent: 'center',
                verticalAlign: 'middle'
            }}className="graphContainer">
        <svg ref={svgRef}></svg>
        <div className="graphButtonContainer">
          {disableLeftArrow ? <div className="clearArrowButton"><FontAwesomeIcon size="2xl" icon={faArrowLeft}/></div> : <button className="solidArrowButton" onClick={()=>updateDown()}><FontAwesomeIcon size="2xl" icon={faArrowLeft}/></button>}
          {disableRightArrow ? <div className="clearArrowButton"><FontAwesomeIcon size="2xl" icon={faArrowRight}/></div> : <button className="solidArrowButton" onClick={()=>updateUp()}><FontAwesomeIcon size="2xl" icon={faArrowRight}/></button>}
        </div>
      </div>
    </div>
  );
};

export default PastLectureGraph;
