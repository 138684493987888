import './styles/App.css';
import React, {useState} from 'react';
import SignIn from './components/SignIn';
import store from './components/store';
import Student from './components/Student';
import { connect } from 'react-redux';
import Logo from './assets/logo.png';
import Teacher from './components/Teacher';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft } from "@fortawesome/free-solid-svg-icons";

function Home({ userID }) {
    
    let [frontPage, setFrontPage] = useState(true);
    let [isStudent, setIsStudent] = useState(false);
    let [isTeacher, setIsTeacher] = useState(false);
    let [bannerMessage, setBannerMessage] = useState('Welcome to Foster');
    let [showBackArrow,setShowBackArrow]=useState(false);
    let [signedIn, setSignedIn] = useState(false);
    let [onMobile,setOnMobile]=useState(false);
    
    
    function student(){
        store.dispatch({type: 'student'})
        store.dispatch({ type: "changePage", payload: "classJoinPage" })
        setFrontPage(false)
        setIsStudent(true)
    }

    function teacher(){
        store.dispatch({type: 'teacher'})
        store.dispatch({ type: "changePage", payload: "classCreatePage" })
        setFrontPage(false)
        setIsTeacher(true)
    }

    function reset(){
        let page=store.getState().curPage;
        
        if(page==='home'){
            setFrontPage(true)
            setIsStudent(false)
            setIsTeacher(false)
            setSignedIn(false)
            setShowBackArrow(false)
        }
        else if(page==='classJoinPage' || page==='classCreatePage'){
            store.dispatch({ type: 'reset' })
            store.dispatch({type:'changePage', payload:'home'})
            setFrontPage(true)
            setIsStudent(false)
            setIsTeacher(false)
        }
        else if(page ==='activeStudentClassPage'){
            store.dispatch({ type: 'reset' })
            store.dispatch({type: 'student'})
            store.dispatch({type:'changePage', payload:'classJoinPage'})
            store.dispatch({
                type: "welcomeMessage",
                payload: "Welcome to Foster",
              });
            setFrontPage(false)
            setIsStudent(true)
        }
        else if(page==='activeTeacherPage'){
            store.dispatch({ type: 'reset' })
            store.dispatch({type: 'teacher'})
            store.dispatch({type:'changePage', payload:'classCreatePage'})
            store.dispatch({
                type: "welcomeMessage",
                payload: "Welcome to Foster",
              });
            setFrontPage(false)
            setIsTeacher(true)
        }
        else if(page==='teacherGraphPage'){
            store.dispatch({ type: 'resetSaveNameAndId' })
            store.dispatch({type: 'teacher'})
            store.dispatch({type:'changePage', payload:'activeTeacherPage'})
            setFrontPage(false)
            setIsTeacher(true)
        }
    }

    store.subscribe(() => {
        setBannerMessage(store.getState().welcomeMessage);
        if(!signedIn && store.getState().userID!==null){
            setSignedIn(true)
        }
        if(!showBackArrow && store.getState().userID!==null){
            setShowBackArrow(true)
        }
        if(store.getState().isMobile===true && !onMobile){
            setOnMobile(true)
        }
    });

    
  
  return (
    <div className=''>
      <div className="title">
        <p>
            
            {showBackArrow && (<button className='back btn bg-transparent' onClick={()=>reset()}><FontAwesomeIcon size="xl" icon={faArrowLeft}/></button>)}
            {bannerMessage}
        </p>
      </div>
        {frontPage && signedIn===true && (
        <div className='centeredContainer'>
            <div>
                <div className='signInContainer' style={{
                    display: 'flex',
                    justifyContent: 'center',
                    verticalAlign: 'middle'
                }}>
                    <div className='signInText'>I am a</div>
                    <button className='signInButton' onClick={()=>student()}>Student</button>
                    <button className='signInButton' onClick={()=>teacher()}>Teacher</button>
                </div>
                </div>
            </div>
            )}
        {frontPage && onMobile===false && signedIn===false && (
        <div className="splash">
            <div style={{display: 'flex'}}>
                <div>
                  
                    <p
                        style={{
                            fontSize: 22
                        }}
                    >
                        <b>Foster</b> is a tool for live lecture feedback.
                    </p>
                    <p
                        style={{
                            fontSize: 18
                        }}
                    >
                        Confused? Feeling bored? Moving too fast? Let your teachers know
                        with <b>Foster</b>!
                    </p>
                </div>
                <div style={{ flex: '20%' }}>
                    <img
                        src={Logo}
                        height={200}
                        alt="Some other screenshot!"
                        style={{ float: 'right' }}
                    />
                </div>
            </div>
            <hr />
            <SignIn primary={true} />
          </div>)}
        {frontPage && onMobile===true && signedIn===false && (
        <div className="splashMobile">
        <div>
            </div>
            <div>
              
                <p
                    style={{
                        fontSize: 22
                    }}
                >
                    <b>Foster</b> is a tool for live lecture feedback.
                </p>
                <p
                    style={{
                        fontSize: 18
                    }}
                >
                    Confused? Feeling bored? Moving too fast? Let your teachers know
                    with <b>Foster</b>!
                </p>
            </div>
        <hr />
        <SignIn primary={true} />
      </div>)}
        {isStudent && (<div>
            <Student/>
        </div>)}
        {isTeacher && (<div>
            <Teacher/>
        </div>)}

    </div>
  );
}

const mapStateToProps = (state) => ({
    userID: state.userID
});

export default connect(mapStateToProps)(Home);
