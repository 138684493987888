import db from '../firebase/Firestore';
import {useState, useEffect} from 'react';
import store from './store';
import { doc, getDoc, Timestamp } from 'firebase/firestore';
import { get } from 'firebase/database';


const ViewData = () => {

    let emotions=["bored","tired","slow","fast","repeat","stressed","lost"];
    let emojis=["😐","😴","🐌","💨","🔁","😰","🧭"];

    const [data,setData]=useState([]);

    const [refresh,setRefresh]=useState(false);
    
    const [dateHolder,setDateHolder]=useState("");

    async function getData(){
        let teacherRef=doc(db,"users",store.getState().userID,"classes",store.getState().className)
        let teacherClassData=(await getDoc(teacherRef)).data();
        let lectureCount=teacherClassData.totalLectures-1;
        teacherRef=doc(db,"users",store.getState().userID,"classes",store.getState().className,"lectures",store.getState().pastLecture);
        let teacherLectureData=(await getDoc(teacherRef)).data();

        let totalEmoji=teacherClassData.bored;
        let lectureEmoji=teacherLectureData.bored;
        getAvg(totalEmoji,lectureEmoji,lectureCount,0);

        totalEmoji=teacherClassData.tired;
        lectureEmoji=teacherLectureData.tired;
        getAvg(totalEmoji,lectureEmoji,lectureCount,1);

        totalEmoji=teacherClassData.slow;
        lectureEmoji=teacherLectureData.slow;
        getAvg(totalEmoji,lectureEmoji,lectureCount,2);

        totalEmoji=teacherClassData.fast;
        lectureEmoji=teacherLectureData.fast;
        getAvg(totalEmoji,lectureEmoji,lectureCount,3);

        totalEmoji=teacherClassData.repeat;
        lectureEmoji=teacherLectureData.repeat;
        getAvg(totalEmoji,lectureEmoji,lectureCount,4);

        totalEmoji=teacherClassData.overwhelmed;
        lectureEmoji=teacherLectureData.overwhelmed;
        getAvg(totalEmoji,lectureEmoji,lectureCount,5);

        totalEmoji=teacherClassData.lost;
        lectureEmoji=teacherLectureData.lost;
        getAvg(totalEmoji,lectureEmoji,lectureCount,6);

        setDateHolder(teacherLectureData.date);
            
        setData(data);
    }

    function getAvg(totalEmoji,lectureEmoji,lectureCount,i){
        if(lectureEmoji===0){
            data.push([0,-1,i]);
            return;
        }
        let avgEmoji=Math.ceil(totalEmoji/lectureCount);
        if(avgEmoji>lectureEmoji){
            data.push([lectureEmoji,(-((avgEmoji-lectureEmoji)/avgEmoji)*100).toFixed(0),i]);
        }
        else{
            data.push([lectureEmoji,(((lectureEmoji/avgEmoji)-1)*100).toFixed(0),i]);
        }
    }

    function createItem(item){
        if(item[0]===0){
            return (<div className='dataItem'>no {emotions[item[2]]}{emojis[item[2]]} reactions</div>)
        }
        else if(item[1]<0){
            return (<div className='dataItem'>{item[0]} {emotions[item[2]]}{emojis[item[2]]} reactions  <div className='decrease'> {-item[1]}% decrease </div> from average</div>)
        }
        else{
            return (<div className='dataItem'> {item[0]} {emotions[item[2]]}{emojis[item[2]]} reactions  <div className='increase'> {item[1]}% increase </div> from average</div>)           
        }     
    }

    async function activate(){
        await getData();
        setRefresh(true);
    }
    

    useEffect(()=>{
        activate()
        },[refresh]);
    
    return (
        <div>
            {store.getState().isMobile === false && (
            <div className='dataContainer'>
                <div className='dataTitle'>{store.getState().pastLecture}</div>
                <div className='dataDate'>from {dateHolder}</div>
                {data.map((item) => (
                    createItem(item)
                ))}
            </div>)}
            {store.getState().isMobile === true && (
            <div className='dataContainerMobile'>
                <div className='dataTitle'>{store.getState().pastLecture}</div>
                <div className='dataDate'>from {dateHolder}</div>
                {data.map((item) => (
                    createItem(item)
                ))}
            </div>)}
        </div>
    )
}

export default ViewData;