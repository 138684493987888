import store from "./store"

const initialState = {
  userID: null,
  role: null,
  xLimit: 25,
  className: null,
  lectureName: null,
  welcomeMessage: "Welcome to Foster",
  teacherID: null,
  classID:null,
  xStart: 0,
  pastLecture: null,
  xMax:25,
  curPage: "home",
  isMobile: false
};

// Use the initialState as a default value
export default function appReducer(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    // Do something here based on the different types of actions
    case "student": {
      return {
        ...state,
        role: "student",
      };
    }
    case "teacher": {
      return {
        ...state,
        role: "teacher",
      };
    }

    case "userUpdate": {
      return {
        ...state,
        userID: action.payload,
      };
    }

    case "xLimitEndUpdate": {
      return {
        ...state,
        xLimit: action.payload,
      };
    }
    case "xMaxUpdate": {
      return {
        ...state,
        xMax: action.payload,
      };
    }

    case "joinClass": {
      return {
        ...state,
        className: action.payload,
      };
    }
    case "setLectureName": {
      return {
        ...state,
        lectureName: action.payload,
      };
    }
    case "setPastLectureName": {
      return {
        ...state,
        pastLecture: action.payload,
      };
    }
    case "setTeacherID": {
      return {
        ...state,
        teacherID: action.payload,
      };
    }

    case "welcomeMessage": {
      return {
        ...state,
        welcomeMessage: action.payload,
      };
    }

    case "setClassID": {
        return {
          ...state,
          classID: action.payload,
        };
      }

    case 'xLimitStartUpdate':{
        return {
            ...state,
            xStart: action.payload
        }
    }
    case 'reset':{

        return {
          ...state,
          role: null,
          xLimit: 25,
          className: null,
          lectureName: null,
          teacherID: null,
          classID:null,
          xStart: 0,
          pastLecture: null,
          xMax:25,
          curPage: "home",
        };
      }

      case 'resetSaveName':{

        return {
          ...state,
          role: null,
          xLimit: 25,
          lectureName: null,
          teacherID: null,
          classID:null,
          xStart: 0,
          pastLecture: null,
          xMax:25,
          curPage: "home",
        };
      }

      case 'resetSaveNameAndId':{

        return {
          ...state,
          role: null,
          xLimit: 25,
          lectureName: null,
          teacherID: null,
          xStart: 0,
          pastLecture: null,
          xMax:25,
          curPage: "home",
        };
      }

      case 'changePage':{
        return {
          ...state,
          curPage: action.payload
        };
      }

      case "isMobile":{
        return {
          ...state,
          isMobile: true
        };
      }

      case 'signOut':{
        return {
          ...state,
          userID:null
        };
      }


 
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
