import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';


const firebaseConfig = {
    apiKey: "AIzaSyDpxPVqET8o2G5IBZFph6DOCHKsQMSPKtw",
    authDomain: "foster-53c56.firebaseapp.com",
    projectId: "foster-53c56",
    storageBucket: "foster-53c56.appspot.com",
    messagingSenderId: "340897393845",
    appId: "1:340897393845:web:ce6b17498877f4316fe2fc",
    measurementId: "G-0KT3ZSVE7K"
  };

const fire = initializeApp(firebaseConfig);
const auth = getAuth();

export default fire;
export const functions = getFunctions();
export { auth };