import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {initializeApp} from 'firebase/app';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';

// MUST RUN FIREBASE CONFIG BEFORE ANY GETTER FUNCTIONS
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    <App />
  </div>
);

//Bootstrap config

const firebaseApp = initializeApp({
  apiKey: "AIzaSyDpxPVqET8o2G5IBZFph6DOCHKsQMSPKtw",
  authDomain: "foster-53c56.firebaseapp.com",
  projectId: "foster-53c56",
  storageBucket: "foster-53c56.appspot.com",
  messagingSenderId: "340897393845",
  appId: "1:340897393845:web:ce6b17498877f4316fe2fc",
  measurementId: "G-0KT3ZSVE7K"
});

const auth = getAuth(firebaseApp);

//Detect Auth State
onAuthStateChanged(auth, user => {
  if(user != null){

  }
  else{

  }
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
