import db from "../firebase/Firestore";
import store from "./store";
import { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  increment,
  getDocs,
  collection,
  deleteDoc
} from "firebase/firestore";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import realTimeDB from "../firebase/RealTimeFirebase";
import { ref, update } from "firebase/database";

function ClassCreate() {
  //Used to determine if the user has any classes
  const [noClasses, setNoClasses] = useState(true);

  //Used to determine if pop-up form for creating a class should be displayed
  const [openForm, setOpenForm] = useState(false);

  //Holds the value of className as being updated
  const [activeName, setActiveName] = useState("");

  //Holds all classes the user has created
  const [usersClasses, setUsersClasses] = useState([]);

  //refresh
  const [refresh, setRefresh] = useState(false);

  //Checks if user has any classes to determine if we should display no class message or not
  async function checkClasses() {
    let classRef = doc(db, "users", store.getState().userID);
    let classes = await getDoc(classRef);
    if (classes.data().classTotal !== 0) {
      setNoClasses(false);
    }
    await fetchClasses();
  }

  //Handles pop-up class name form as it is being updated
  const handleChange = (event) => {
    setActiveName(event.target.value);
  };

  //Handles pop-up class name when submitted
  async function submitClass() {
    setOpenForm(false);
    await updateDoc(doc(db, "users", store.getState().userID), {
      classTotal: increment(1),
    });
    let newID = await getClassID();
    let newClass = {
      name: activeName,
      classCode: newID,
      totalLectures: 1,
    };

    //Add class to hasmap of classes
    let value = store.getState().userID + "_" + activeName;
    let classIDsRef = doc(db, "classIDs", "map");
    let classes = await getDoc(classIDsRef);
    let map = classes.data().classes;
    map[newID] = value;
    await updateDoc(doc(db, "classIDs", "map"), {
      classes: map,
    });
    await setDoc(
      doc(db, "users", store.getState().userID, "classes", activeName),
      newClass
    );
    checkClasses();
  }

  //Generates unique unused ID for class
  async function getClassID() {
    let classIDsRef = doc(db, "classIDs", "IDs");
    let classIDs = await getDoc(classIDsRef);
    let classCodes = classIDs.data().list;
    let newCodeIndex = true;
    let newClassID = -1;
    while (newCodeIndex === true) {
      newClassID = Math.floor(100000 + Math.random() * 900000);
      newCodeIndex = binarySearch(
        classCodes,
        newClassID,
        0,
        classCodes.length - 1
      );
    }
    classCodes.splice(newCodeIndex, 0, newClassID);
    await updateDoc(doc(db, "classIDs", "IDs"), {
      list: classCodes,
    });
    return newClassID;
  }

  //recursive binary search to find class ID, returns true if the item is already in the list
  function binarySearch(arr, x, start, end) {
    // Base Condition
    if (start > end) return start;

    // Find the middle index
    let mid = Math.floor((start + end) / 2);

    // Compare mid with given key x
    if (arr[mid] === x) return true;

    // If element at mid is greater than x,
    // search in the left half of mid
    if (arr[mid] > x) return binarySearch(arr, x, start, mid - 1);
    // If element at mid is smaller than x,
    // search in the right half of mid
    else return binarySearch(arr, x, mid + 1, end);
  }
  //Fetches the list of classes that user owns
  async function fetchClasses() {
    let tempClasses = [];
    let classes = await getDocs(
      collection(db, "users", store.getState().userID, "classes")
    );
    classes.forEach((doc) => {
      tempClasses.push(doc.data());
    });
    await setUsersClasses(tempClasses);
  }

  //sets the selected class name in the store
  function initiateClass(item) {
    store.dispatch({ type: "joinClass", payload: item.name });
    store.dispatch({ type: "setClassID", payload: item.classCode });
    store.dispatch({ type: "changePage", payload: "activeTeacherPage" })
    store.dispatch({
      type: "welcomeMessage",
      payload: "Welcome to " + createName(item.name),
    });
  }

  function createName(name){
    if(store.getState().isMobile===false){
      if(name.length>34){
        return name.substring(0,34)+"..."
      }
      return name
    }
    else{
      if(name.length>7){
        return name.substring(0,7)+"..."
      }
      return name
    }
  }

  //sets teacher status to online in realtime db
  function setOnlineStatus() {
    update(ref(realTimeDB,"users/"+store.getState().userID), {
      online: true,
      activeLecture: null,
    });
  }

  async function deleteClass(event, name){
    event.stopPropagation();
    await deleteDoc(doc(db, "users", store.getState().userID,"classes",name));
    await updateDoc(doc(db, "users", store.getState().userID), {
      classTotal: increment(-1),
    });
    setRefresh(!refresh);
  }

  //UseEffect that runs checkClasses()
  useEffect(() => {
    checkClasses();
    fetchClasses();
    setOnlineStatus();
  }, [openForm,refresh]);

  return (
    <div>
    <div style={{
           display: 'flex', 
           flexDirection: 'column', 
           justifyContent: 'center',
           alignItems: 'center'
         }}
       >
         <div className="lectureList">
             <div className="lectureNameForm sticky-top">
               <input
                 className="classNameInput"
                 type="text"
                 id="update"
                 name="update"
                 onChange={handleChange}
                 value={activeName}
                 placeholder="Enter a New Class Name"
               />
               {store.getState().isMobile === false && (
               <button
                 className="submitLectureNameButton"
                 onClick={() => submitClass()}
               >
                 Create Class
               </button>
               )}
               {store.getState().isMobile === true && (
               <button
                 className="submitLectureNameButtonMobile"
                 onClick={() => submitClass()}
               >
                 Create Class
               </button>
               )}
             </div>
             {noClasses===true && (

               <div className="noClassMessage">You have not created any classes</div>
             )}
             <div className="listHolder">
             {usersClasses.map((lecture) => {
             return <button 
                 className="lectureListItem"
                 style={{ 
                   backgroundColor: 'rgb(76, 198, 239)' 
                 }}
                 onClick={() => initiateClass(lecture)}
               >
                <div>
                   <button className = 'deleteButton' onClick={(event) => deleteClass(event,lecture.name)}><FontAwesomeIcon icon={faTrash}/></button>
                  <div className="lectureListItemText">{createName(lecture.name)}</div>
                 </div>
               </button>
             })}
             </div>
         </div>
       </div>
 </div>
  );
}
export default ClassCreate;
