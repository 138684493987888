import PastLectureGraph from "./PastLectureGraph";
import ViewData from "./ViewData";
import store from "./store";

const PastLecture = () => {
    return (
        <div>
        {store.getState().isMobile === false && (
        <div className="centeredContainerPastLecture">
            <div className="pastLectureContainer">
                <PastLectureGraph/>
            <ViewData/>
            </div>
        </div>)}
        {store.getState().isMobile === true && (
        <div className="centeredContainerPastLectureMobile">
            <div className="pastLectureContainer">
            <ViewData/>
            </div>
        </div>)}
        </div>
    )
}

export default PastLecture;