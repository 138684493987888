import ClassCreate from "./ClassCreate";
import store from "./store";
import { useState } from "react";
import ActiveTeacherClass from "./ActiveTeacherClass";
import realTimeDB from "../firebase/RealTimeFirebase";
import GraphTest from './GraphTest';
import {ref, onDisconnect,set} from "firebase/database";
import PastLecture from "./PastLecture";

function Teacher() {

  //sets user online status to false on disconnect
  onDisconnect(ref(realTimeDB, "users/" + store.getState().userID)).update({
    online: false,
    activeLecture: null
  });

  let [refresh,setRefresh] = useState(false);

  store.subscribe(() => {
    console.log(store.getState());
    setRefresh(!refresh);
  });

  function endClass(){
    store.dispatch({type: "setPastLectureName", payload:store.getState().lectureName});
    set(ref(realTimeDB, "users/" + store.getState().userID),({
      online: false,
      activeLecture: null
    }));
    store.dispatch({type: "setLectureName", payload: null});
  }

  return (
    <div>
      {store.getState().className === null && store.getState().pastLecture === null && (
        <div>
          <ClassCreate/>
        </div>
      )}
      {store.getState().className !== null && store.getState().lectureName === null && store.getState().pastLecture === null && (
        <div>
          <ActiveTeacherClass/>
        </div>
      )}
      {store.getState().lectureName !== null && (
        <div>
          {store.getState().isMobile === false && (
            <div>
            <button className="endClassButton" onClick={() => endClass()}>End Class</button>
            <GraphTest/> 
            </div>
            )}
            {store.getState().isMobile === true && (
            <div>
            <button className="endClassMobile" onClick={() => endClass()}>End Class</button>
            </div>
            )}
        </div>)}
      {store.getState().className !== null && store.getState().pastLecture !== null &&(
        <div>
          <PastLecture/>
        </div>
      )}
    </div>
  );
}

export default Teacher;
