import { signInWithPopup, GoogleAuthProvider, getAuth } from "firebase/auth";
import { collection, doc, getDocs, setDoc, updateDoc} from "firebase/firestore";
import db from "../firebase/Firestore";
import realTimeDB from "../firebase/RealTimeFirebase";
import {ref,set} from "firebase/database";
import store from "./store";

const provider = new GoogleAuthProvider();

const signIn = (event) => {
  signInWithPopup(getAuth(), provider)
    .then(async (result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const newUser = result.user;


      store.dispatch({ type: "userUpdate", payload: newUser.uid });

      const users = await getDocs(collection(db, "users"));

      let newAccount = true;
      let userData = {
        identifier: newUser.email,
        bored: 0,
        tired: 0,
        slow: 0,
        fast: 0,
        repeat: 0,
        overwhelmed: 0,
        lost: 0,
        classTotal: 0,
        joinedClasses: 0,
        joinedClassTotal: 0,
      };

      users.forEach((user) => {
        if (user.id === newUser.uid) {
          newAccount = false;
          userData = user;
        }
      });

      if (newAccount) {
        await setDoc(doc(db, "users", newUser.uid), userData);
        set(ref(realTimeDB, "users/" + newUser.uid), {
          online: true,
          activeLecture: "null"
      });
      }
      else{
        await updateDoc(doc(db, "users", newUser.uid),
        {
          bored:0,
          tired:0,
          slow:0,
          fast:0,
          repeat:0,
          overwhelmed:0,
          lost:0,
        }

        );}
    })
    .catch((error) => {

      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.customData.email;
      const credential = GoogleAuthProvider.credentialFromError(error);

    });
};

const SignIn = (props) => {
    return (
        <button
            className={
                `${props.primary ? 'homeSignIn' : 'signInButton'} 
                initSignIn`
            }
            onClick={signIn}
        >
            Sign In{props.primary && ' to get started'}!
        </button>
    );
}

export default SignIn;