import ClassJoin from "./ClassJoin";
import store from "./store";
import { useState,useEffect } from "react";
import ActiveStudentClass from "./ActiveStudentClass";

function Student() {
  //used to conditionally render class join and the class page
  const [inClass, setInClass] = useState(false);


  store.subscribe(() => {
    if (store.getState().className != null && !inClass) {
      setInClass(true);
    }
    else if(inClass && store.getState().curPage==='classJoinPage'){
      setInClass(false);
    }
  });


  return (
    <div>
      {inClass === false && (
        <div>
          <ClassJoin />
        </div>
      )}
      {inClass === true && (
        <div>
          <ActiveStudentClass />
        </div>
      )}
    </div>
  );
}

export default Student;
