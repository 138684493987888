import React from "react";
import { doc, increment, updateDoc } from "firebase/firestore";
import db from "../firebase/Firestore";
import { useEffect, useState } from "react";
import store from "./store";

let changeValue = doc(db, "buttonClick", "emojiTest");

//Checks store each time state is changed, setting the user to uid currently signed in
store.subscribe(() => {
  if(store.getState().userID != null)
    changeValue = doc(db, "users", store.getState().userID);
});

//Generates button animation
function materializeEffect(event) {
  const circle = document.createElement("div");
  const x = event.layerX;
  const y = event.layerY;
  circle.classList.add("circle");
  circle.style.left = `${x}px`;
  circle.style.top = `${y}px`;
  this.appendChild(circle);
}

//Send increased emoji count to database
async function inc(emojiCode) {
  await updateDoc(changeValue, {
    [emojiCode]: increment(1),
  });
  await updateDoc(
    doc(
      db,
      "users",
      store.getState().teacherID,
      "classes",
      store.getState().className,
      "lectures",
      store.getState().lectureName
    ),
    {
      [emojiCode]: increment(1),
    }
  );
  await updateDoc(
      doc(
        db,
        "users",
        store.getState().teacherID,
        "classes",
        store.getState().className
      ),
      {
        [emojiCode]: increment(1),
      }
    );
}
//check out bungee font
const Btns = () => {
    const [coolDown, setCoolDown] = useState(false); // State for cooldown
    const [pressed, setPressed] = useState({}); // State for tracking pressed

    // Function to handle button click
    const handleButtonClick = async (emojiCode) => {
        if (!coolDown && !pressed[emojiCode]) {
        // Check if button is not in cooldown and not pressed before
        // console.log("not in cooldown, not pressed before");
        await inc(emojiCode); // Call the inc() function to update the database
        // console.log("Emoji pressed");
        setCoolDown(true); // Set cooldown to true
        setPressed((prevState) => ({ ...prevState, [emojiCode]: true })); // Set button as pressed
        
        setTimeout(() => {
            // After 5 seconds, reset cooldown and button pressed state
            setCoolDown(false);
            setPressed((prevState) => ({ ...prevState, [emojiCode]: false }));
        }, 30000); // 30000= 30 second cooldown
        }
        // console.log("Can't press emoji due to cooldown");
    };

    //Assigns the animation to button, list is hard capped at 7, change if emoji button count is changed
    useEffect(()=>{
        var button=<button></button>
        if(store.getState().isMobile === false)
          var btns=document.querySelectorAll(".button");
        else{
          var btns=document.querySelectorAll(".mobileButton");
        }
        if(btns.length>0)
            button=btns
        for(let i=0;i<7;i++){
            button[i].addEventListener("click", materializeEffect);
        }
    })

    // CSS class for buttons during cooldown
    let buttonCoolDownClass = coolDown ? "button-cooldown" : "";
    let buttonCoolDownClassMobile = coolDown ? "button-cooldownMobile" : "";

    return(
        <div>
          {store.getState().isMobile === false && (
            <div className="eButtonContainer" style={{
            display: 'flex',
            justifyContent: 'center',            
            }}>
                <button className={`button ${buttonCoolDownClass}`} onClick={() => handleButtonClick("bored")}>Bored 😐</button>
                <button className={`button ${buttonCoolDownClass}`} onClick={() => handleButtonClick("tired")}>Tired 😴</button>
                <button className={`button ${buttonCoolDownClass}`} onClick={() => handleButtonClick("slow")}>Too Slow 🐌</button>
                <button className={`button ${buttonCoolDownClass}`} onClick={() => handleButtonClick("fast")}>Too Fast 💨</button>
                <button className={`button ${buttonCoolDownClass}`} onClick={() => handleButtonClick("repeat")}>Repeat 🔁</button>
                <button className={`button ${buttonCoolDownClass}`} onClick={() => handleButtonClick('overwhelmed')}>Stressed 😰</button>
                <button className={`button ${buttonCoolDownClass}`} onClick={() => handleButtonClick('lost')}>Lost 🧭</button>
            </div>
            )}
            {store.getState().isMobile === true && (
            <div className="eButtonContainerMobile">
                <button className={`mobileButton ${buttonCoolDownClassMobile}`} onClick={() => handleButtonClick("bored")}>Bored 😐</button>
                <button className={`mobileButton ${buttonCoolDownClassMobile}`} onClick={() => handleButtonClick("tired")}>Tired 😴</button>
                <button className={`mobileButton ${buttonCoolDownClassMobile}`} onClick={() => handleButtonClick("slow")}>Too Slow 🐌</button>
                <button className={`mobileButton ${buttonCoolDownClassMobile}`} onClick={() => handleButtonClick("fast")}>Too Fast 💨</button>
                <button className={`mobileButton ${buttonCoolDownClassMobile}`} onClick={() => handleButtonClick("repeat")}>Repeat 🔁</button>
                <button className={`mobileButton ${buttonCoolDownClassMobile}`} onClick={() => handleButtonClick('overwhelmed')}>Stressed 😰</button>
                <button className={`mobileButton ${buttonCoolDownClassMobile}`} onClick={() => handleButtonClick('lost')}>Lost 🧭</button>
            </div>
            )}
        </div>

    );
}
export default Btns;
