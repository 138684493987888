import GraphTest from "./GraphTest";
import Btns from "./emojiButtons";
import { useState, useEffect } from "react";
import store from "./store";
import realTimeDB from "../firebase/RealTimeFirebase";
import { ref, onValue } from "firebase/database";
import { doc } from "firebase/firestore";

function ActiveStudentClass() {
  //Used to verify that the teacher has initiated Class
  let [waitingToStart, setWaitingToStart] = useState(true);

  //Used to verify class has started and then endd to show end screen
  let [classOver, setClassOver] = useState(false);

  let [waitingToEnd, setWaitingToEnd] = useState(false);

  
  const classStatus = ref(realTimeDB,"users/"+store.getState().teacherID);
  if(waitingToStart === true){
    onValue(classStatus, (snapshot) => {
    const data = snapshot.val();
    if(data.activeLecture != null){
      setWaitingToStart(false);
      store.dispatch({
        type: "setLectureName",
        payload: data.activeLecture,
      });
      setWaitingToEnd(true);
    }
  });
}

if(waitingToEnd === true){
  onValue(classStatus, (snapshot) => {
  const data = snapshot.val();
  if(data.online === false){
    setClassOver(true);
    setWaitingToEnd(false);
  }
});
}

  useEffect(() => {store.dispatch({type:'changePage', payload:'activeStudentClassPage'})}, [waitingToStart,waitingToEnd]);

  return (
    <div>
      {waitingToStart === false && classOver===false && (
        <div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          verticalAlign: 'middle'
      }}>
        {store.getState().isMobile === false && (<GraphTest/>)}
          </div>
          <Btns />
          </div>
      )}
      {classOver === true && (
        <div>
          <div className="waitMessage">class has ended</div>
        </div>
      )}
      {waitingToStart === true &&  store.getState().isMobile === false && (
        <div>
          <div className="waitMessage">Waiting for class to start</div>
          <div class="banter-loader">
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
          </div>
        </div>
      )}
      {waitingToStart === true &&  store.getState().isMobile === true && (
        <div>
          <div className="waitMessageMobile">Waiting for class to start</div>
          <div class="banter-loader">
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
            <div class="banter-loader__box"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ActiveStudentClass;
