import '../index.css'
import db from "../firebase/Firestore";
import store from "./store";
import { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  increment,
  getDocs,
  collection
} from "firebase/firestore";
import realTimeDB from "../firebase/RealTimeFirebase";
import { ref, update } from "firebase/database";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



function ActiveTeacherClass(props) {
  //Holds lecture list
  let [lectures, setLectures] = useState([]);

  //Holds the value of lectureName as being updated
  const [activeName, setActiveName] = useState("");

  //used for useEffect
  let [refresh, setRefresh] = useState(false);

  //Used to show the classes code when button clicked
  let [showCode, setShowCode] = useState(false);

  let [noLectures,setNoLectures] = useState(false);

  //Fetches the list of lectures in this class
  async function fetchLectures() {
    let tempLectures = [];
    let lecturesFetch = await getDocs(
      collection(
        db,
        "users",
        store.getState().userID,
        "classes",
        store.getState().className,
        "lectures"
      )
    );
    lecturesFetch.forEach((doc) => {
      tempLectures.push(doc.data());
    });
    await setLectures(tempLectures);
    if(tempLectures.length === 0){
      setNoLectures(true);
    }
  }

  //Sends new Lecture name to collection
  async function submitLecture() {

    let date=new Date();
    const month = date.toLocaleString('default', { month: 'long' }); // Get the full month name
    const day = date.getDate(); // Get the day of the month
    const year = date.getFullYear(); // Get the full year

    let hour = date.getHours(); // Get the hour (0-23)
    let minute = date.getMinutes(); // Get the minute (0-59)

    let period = 'AM'; // Initialize period as AM

    // Adjust hour and period for PM
    if (hour >= 12) {
        period = 'PM';
    if (hour > 12) {
        hour -= 12;
        }
    }

    if(minute<10){
        minute="0"+minute;
    }

    const formattedDate = `${month} ${day}, ${year} at ${hour}:${minute} ${period}`;
    //creates the new lecture in firestore
    let newLecture = {
      bored: 0,
      tired: 0,
      slow: 0,
      fast: 0,
      repeat: 0,
      overwhelmed: 0,
      lost: 0,
      name: activeName,
      date: formattedDate
    };
    await setDoc(
      doc(
        db,
        "users",
        store.getState().userID,
        "classes",
        store.getState().className,
        "lectures",
        activeName
      ),
      newLecture
    );
    await updateDoc(
      doc(
        db,
        "users",
        store.getState().userID,
        "classes",
        store.getState().className,
      ),{
        totalLectures: increment(1),
      }
    );
    setActiveLecture(newLecture);
    setNoLectures(false);
    setRefresh(!refresh);
    store.dispatch({
      type: "setLectureName",
      payload: activeName
    });
    store.dispatch({type:'changePage', payload:'teacherGraphPage'})
  }

  //Shows teacher data from previous lectures
  async function showPastLecture(name){
    console.log(name);
    store.dispatch({
      type: "setPastLectureName",
      payload: name
    });
    store.dispatch({type:'changePage', payload:'teacherGraphPage'})
  }

  //Sets the active lecture in firebase for students to join
  async function setActiveLecture(selectedlecture) {
    //update the activeLecture value in realtimeDB
    update(ref(realTimeDB,"users/"+store.getState().userID), {
      online: true,
      activeLecture: selectedlecture.name
    });
  }

  async function getActiveLecture() {
    try {
      const docRef = doc(realTimeDB, "users", store.getState().userID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        const activeLecture = userData.activeLecture;
        return activeLecture;
      } else {
        console.error("User data not found");
      }
    } catch (error) {
      console.error("Error getting user data", error);
    }
  }
  

  // ends class by setting activeLecture back to null
  async function endClass() {
    await updateDoc(
      doc(
        db,
        "users",
        store.getState().userID,
        "classes",
        store.getState().className
      ),
      {
        activeLecture: null,
      }
    );

      props.endClass();
  }
  

  //Handles pop-up class name form as it is being updated
  const handleChange = (event) => {
    setActiveName(event.target.value);
  };

  async function getLectureCount(){
    const q = await getDoc(
      doc(
        db,
        "users",
        store.getState().userID,
        "classes",
        store.getState().className
      )
    );
    await setActiveName("lecture" + q.data().totalLectures);
  }

  //store.subscribe
  store.subscribe(() => {
    if(activeName!=="" && store.getState().curPage==='activeTeacherPage'){
      setRefresh(!refresh);
    }
  });

  useEffect(() => {
    getLectureCount();
    fetchLectures();
  }, [refresh]);  

  function displayCode(){
    setShowCode(!showCode);
  }

  function createName(name){
    if(store.getState().isMobile===false){
      if(name.length>34){
        return name.substring(0,34)+"..."
      }
      return name
    }
    else{
      if(name.length>7){
        return name.substring(0,11)+"..."
      }
      return name
    }
  }

  return (
    <div>
      {showCode ? 
        <div>
          <div 
            style={{
              marginLeft: 5,
              marginRight: 5,
              marginTop: 5,
              marginBottom: 5,
              padding: 10,
              paddingLeft: '4rem',
              paddingRight: '4rem',
              width: "100%",
            }}
          >
            <button className="classCodeButton" onClick={()=>displayCode()}>Hide Class Code</button>
          </div>
          {!store.getState().isMobile && (<div className="classCode">{store.getState().classID}</div>)}
          {store.getState().isMobile && (<div className="classCodeMobile">{store.getState().classID}</div>)}
        </div> : 
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 5,
              marginRight: 5,
              marginTop: 5,
              marginBottom: 5,
              padding: 10,
              paddingLeft: '4rem',
              paddingRight: '4rem',
              width: "100%",
            }}
          >
            <button className="classCodeButton" onClick={()=>displayCode()}>Show Class Code</button>
          </div>
          <div style={{
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div className="lectureList">
                <div className="lectureNameForm sticky-top">
                  <input
                    className="classNameInput"
                    type="text"
                    id="update"
                    name="update"
                    onChange={handleChange}
                    value={activeName}
                    placeholder="Enter Class Name"
                  />
                  {store.getState().isMobile === false && (
                  <button
                    className="submitLectureNameButton"
                    onClick={() => submitLecture()}
                  >
                    start lecture
                  </button>
                  )}
                  {store.getState().isMobile === true && (
                  <button
                    className="submitLectureNameButtonMobile"
                    onClick={() => submitLecture()}
                  >
                    start lecture
                  </button>
                  )}
                </div>
                <div className='listHolder'>
                {lectures.map((lecture) => {
                return <button 
                    className="lectureListItem"
                    style={{ 
                      backgroundColor: lecture.name === getActiveLecture() ? 'rgb(53, 154, 187)' : 'rgb(76, 198, 239)'
                    }}
                    onClick={() => showPastLecture(lecture.name)}
                  >  
                        {createName(lecture.name)}
                        <div className='dataDate'>{lecture.date}</div>

                  </button>
                })}
                {noLectures === true && (
                  <div className='noClassMessage'> You Havent held Any Lectures </div>
                )}
                </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default ActiveTeacherClass;

