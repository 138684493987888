import React from 'react';
import Home from './Home';
import { Provider } from 'react-redux';
import store from './components/store';
import { isMobile } from "react-device-detect";
import {useEffect} from 'react';
  

//make it so conditionals are based off of teh store student and teacher states not these local variables
//Connect emojo buttons

function App() {

  const mobile = isMobile;

  document.body.style = 'background: rgb(232, 138, 232)';
  
  //useEffect with no dependencies that sets store update device to mobile
  useEffect(() => {
    if(mobile!=null && mobile===true){
      store.dispatch({type:'isMobile'})
    }
    console.log(store.getState())
  },[])

  return (
    <Provider store={store}><Home /></Provider>
  );
}

export default App;
