import db from "../firebase/Firestore";
import store from "./store";
import { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  increment,
  collection,
  getDocs,
  deleteDoc
} from "firebase/firestore";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ClassJoin() {
  //Used to determine if the user has any classes
  const [noClasses, setNoClasses] = useState(true);

  //Used to determine if pop-up form for creating a class should be displayed
  const [openForm, setOpenForm] = useState(false);

  //Holds the value of className as being updated
  const [activeName, setActiveName] = useState("");

  //Holds all classes the user has created
  const [usersClasses, setUsersClasses] = useState([]);

  

  //Checks if user has any classes to determine if we should display no class message or not
  async function checkClasses() {
    let classRef = doc(db, "users", store.getState().userID);
    let classes = await getDoc(classRef);
    if (classes.data().joinedClassTotal !== 0) {
      setNoClasses(false);
    }
    await fetchClasses();
  }

  //Handles pop-up class name form as it is being updated
  const handleChange = (event) => {
    setActiveName(event.target.value);
  };

  //Handles pop-up class name when submitted
  async function submitClass() {
    setOpenForm(false);
    let classIDsRef = doc(db, "classIDs", "map");
    let classes = await getDoc(classIDsRef);
    let map = classes.data().classes;
    let classKey = map[activeName];
    if (classKey === undefined) {
      alert("This class does not exist");
      return;
    }
    await updateDoc(doc(db, "users", store.getState().userID), {
      joinedClassTotal: increment(1),
    });
    let teacherID = classKey.split("_")[0];
    let className = classKey.split("_")[1];
    let newClass = {
      name: className,
      teacher: teacherID,
    };
    await setDoc(
      doc(db, "users", store.getState().userID, "joinedClasses", className),
      newClass
    );
    setOpenForm(!openForm);
  }

  //Fetches the list of classes that user owns
  async function fetchClasses() {
    let tempClasses = [];
    let classes = await getDocs(
      collection(db, "users", store.getState().userID, "joinedClasses")
    );
    classes.forEach((doc) => {
      tempClasses.push(doc.data());
    });
    await setUsersClasses(tempClasses);
  }

  async function initiateClass(item) {
    await updateDoc(doc(db, "users", store.getState().userID),
        {
          bored:0,
          tired:0,
          slow:0,
          fast:0,
          repeat:0,
          overwhelmed:0,
          lost:0,
        });
    store.dispatch({ type: "joinClass", payload: item.name });
    store.dispatch({
      type: "welcomeMessage",
      payload: "Welcome to " + createName(item.name),
    });
    store.dispatch({ type: "setTeacherID", payload: item.teacher });
    let lectureID = await getDoc(
      doc(db, "users", item.teacher, "classes", item.name)
    );
  }

  function createName(name){
    if(store.getState().isMobile===false){
      if(name.length>34){
        return name.substring(0,34)+"..."
      }
      return name
    }
    else{
      if(name.length>7){
        return name.substring(0,7)+"..."
      }
      return name
    }
  }

  async function deleteClass(event, name){
    event.stopPropagation();
    await deleteDoc(doc(db, "users", store.getState().userID,"joinedClasses",name));
    await updateDoc(doc(db, "users", store.getState().userID), {
      joinedClassTotal: increment(-1),
    });
    setOpenForm(!openForm);
  }

  //UseEffect that runs checkClasses()
  useEffect(() => {
    checkClasses();
    fetchClasses();
  }, [openForm]);

  return (
    <div>
       <div style={{
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div className="lectureList">
                <div className="lectureNameForm sticky-top">
                  <input
                    className="classNameInput"
                    type="text"
                    id="update"
                    name="update"
                    onChange={handleChange}
                    value={activeName}
                    placeholder="Enter a Class Code"
                  />
                  <button
                    className="submitLectureNameButton"
                    onClick={() => submitClass()}
                  >
                    Join Class
                  </button>
                </div>
                {noClasses===true && (
                  <div className="noClassMessage">You are not enrolled in any classes</div>
                )}
                <div className="listHolder">
                {usersClasses.map((lecture) => {
                return <button 
                    className="lectureListItem"
                    style={{ 
                      backgroundColor: 'rgb(76, 198, 239)' 
                    }}
                    onClick={() => initiateClass(lecture)}
                  >
                   <div>
                   <button className = 'deleteButton' onClick={(event) => deleteClass(event,lecture.name)}><FontAwesomeIcon icon={faTrash}/></button>
                  <div className="lectureListItemText">{createName(lecture.name)}</div>
                 </div>
                  </button>
                })}
                </div>
            </div>
          </div>
    </div>
  );
}
export default ClassJoin;
